import React, { useState } from "react";
import clsx from "clsx";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Button from "@material-ui/core/Button";

import TableStatus from "./TableStatus";
import TablePRdownloads from "./TablePR/TablePRdownloads";
import TablePRstatistics from "./TablePR/TablePRstatistics";
import TableTRdownloads from "./TableTR/TableTRdownloads";
import TableTRstatistics from "./TableTR/TableTRstatistics";

let drawerWidth = 240;

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: "70px 0px 0px 5px",
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

function LoggedIn(props) {
  const { admin, dataStore, logOut } = props;
  let classes = useStyles();
  let [open, setOpen] = useState(false);

  function clickMenu() {
    setOpen(!open);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={clickMenu}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <Switch>
              <Route path="/table-tr-statistics">"Title Master Report" - Reports</Route>
              <Route path="/table-tr-downloads">"Title Master Report" - Downloads</Route>
              <Route path="/table-pr-statistics">"Platform Master Report" - Reports</Route>
              <Route path="/table-pr-downloads">"Platform Master Report" - Downloads</Route>
              <Route path="/">Usage statistics for e-resources</Route>
            </Switch>
          </Typography>
          <Button variant="contained" onClick={logOut}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={clickMenu}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Data Sheet" />
            </ListItem>
          </Link>
          <Link to="/table-pr-downloads" style={{ color: "inherit", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary="PR - Downloads" />
            </ListItem>
          </Link>
          <Link to="/table-pr-statistics" style={{ color: "inherit", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="PR - Reports" />
            </ListItem>
          </Link>
          <Link to="/table-tr-downloads" style={{ color: "inherit", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary="TR - Downloads" />
            </ListItem>
          </Link>
          <Link to="/table-tr-statistics" style={{ color: "inherit", textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="TR - Reports" />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <main className={classes.content}>
        <Switch>
          <Route path="/table-tr-downloads">
            <TableTRdownloads admin={admin} />
          </Route>
          <Route path="/table-pr-statistics">
            <TablePRstatistics admin={admin} />
          </Route>
          <Route path="/table-pr-downloads">
            <TablePRdownloads admin={admin} />
          </Route>
          <Route path="/table-tr-statistics">
            <TableTRstatistics dataStore={dataStore} />
          </Route>
          <Route path="/">
            <TableStatus admin={admin} />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default LoggedIn;
