export default function dataStore() {
  let searchWorkerTR;
  return {
    initSearchTR: async function () {
      if (searchWorkerTR) return;

      searchWorkerTR = {
        worker: new Worker(new URL("workerSearchTR.js", import.meta.url), {
          type: "module",
        }),
        post: function (type, value) {
          const id = getID();
          return new Promise((res) => {
            searchWorkerTR.worker[id] = res;
            searchWorkerTR.worker.postMessage({ payload: { type, value, id } });
          });
        },
      };

      searchWorkerTR.worker.onmessage = function (message) {
        const { data, id } = message.data.payload;
        searchWorkerTR.worker[id](data);
        delete searchWorkerTR.worker[id];
      };

      const data = await searchWorkerTR.post("INITIALIZE");
      // console.log(data);
    },
    searchTR: async function (type, value) {
      const values = await searchWorkerTR.post(type, value);
      return values;
    },
  };
}

function getID() {
  const str = "qwertyuiopasdfghjklzxcvbnm";
  let toReturn = "";
  for (let i = 0; i < 10; i++) {
    toReturn += str[Math.floor(Math.random() * str.length)];
  }
  return toReturn;
}
