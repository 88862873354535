import React, { useEffect, useReducer, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { requestRestricted } from "../requests";
import DashboardPR from "./DashboardPR";

let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

let columns = [
  {
    field: "entryID",
    headerName: "ID",
    width: 50,
    sortable: false,
    cellClassName: "small-letters-cell",
  },
  {
    field: "country",
    headerName: "Country",
    width: 110,
  },
  {
    field: "institution",
    headerName: "Institution",
    minWidth: 200,
    flex: 2,
  },
  {
    field: "publisher",
    headerName: "Publisher",
    minWidth: 150,
    flex: 1.5,
  },
];

function reducer(state, action) {
  switch (action.type) {
    case "initialize":
      return initialize(action.payload);
    case "select - type":
      return selectType(action.payload);
    case "select - change":
      return selectChange(action.payload);
    case "toggle accordion":
      return toggleAccordion();
    case "change date":
      return setDate(action.payload);
    case "start downloading dashboard data":
      return startDownloading();
    case "finish downloading dashboard data":
      return finishDownloading(action.payload);
  }

  function finishDownloading(payload) {
    let { dataForDashboard } = payload;
    return {
      ...state,
      dataForDashboard,
      publishersSelectedLocked: state.publishersSelected,
      institutionsSelectedLocked: state.institutionsSelected,
      countriesSelectedLocked: state.countriesSelected,
      dataForDashboardBeingDownloading: false,
    };
  }

  function startDownloading() {
    return {
      ...state,
      dataForDashboardBeingDownloading: true,
    };
  }

  function setDate(payload) {
    let { suffix, name, value } = payload;

    if (suffix == "From" && name == "year" && (value > state.dateTo.year || (value == state.dateTo.year && state.dateFrom.month > state.dateTo.month))) {
      return state;
    }

    if (suffix == "To" && name == "year" && (value < state.dateFrom.year || (value == state.dateFrom.year && state.dateFrom.month > state.dateTo.month))) {
      return state;
    }

    if (suffix == "From" && name == "month" && state.dateFrom.year == state.dateTo.year && value > state.dateTo.month) {
      return state;
    }

    if (suffix == "To" && name == "month" && state.dateFrom.year == state.dateTo.year && value < state.dateFrom.month) {
      return state;
    }

    return {
      ...state,
      [`date${suffix}`]: {
        ...state[`date${suffix}`],
        [name]: value,
      },
    };
  }

  function toggleAccordion() {
    return {
      ...state,
      expanded: !state.expanded,
    };
  }

  function selectType({ type, value }) {
    return {
      ...state,
      [type]: value,
    };
  }

  function selectChange({ type, value }) {
    let tempState = {
      ...state,
      [type]: value,
    };
    tempState = filterData(tempState);
    if (tempState.dataFiltered.length == 0) {
      return {
        ...tempState,
        publishers: uniques(state.data, "publisher"),
        institutions: uniques(state.data, "institution"),
        countries: uniques(state.data, "country"),
        expanded: false,
      };
    } else {
      return tempState;
    }
  }

  function initialize(payload) {
    let { data } = payload;
    return {
      ...state,
      data,
      publishers: uniques(data, "publisher"),
      institutions: uniques(data, "institution"),
      countries: uniques(data, "country"),
    };
  }

  function filterData(state) {
    let { publishersSelected, institutionsSelected, countriesSelected } = state;
    const dataFiltered = state.data.filter((x) => {
      let passesFilters = false;

      if (publishersSelected.length > 0) {
        passesFilters = publishersSelected.indexOf(x.publisher) != -1;
        if (!passesFilters) return false;
      }

      if (institutionsSelected.length > 0) {
        passesFilters = institutionsSelected.indexOf(x.institution) != -1;
        if (!passesFilters) return false;
      }

      if (countriesSelected.length > 0) {
        passesFilters = countriesSelected.indexOf(x.country) != -1;
        if (!passesFilters) return false;
      }

      return passesFilters;
    });

    return {
      ...state,
      dataFiltered,
      publishers: uniques(dataFiltered, "publisher"),
      institutions: uniques(dataFiltered, "institution"),
      countries: uniques(dataFiltered, "country"),
    };
  }

  function uniques(data, field) {
    return data.map((x) => x[field]).filter((x, i, arr) => arr.indexOf(x) == i);
  }
}

function generateMonthArray(start, end) {
  let year = parseInt(start.year);
  let month = parseInt(start.month + 1);

  let yearFinal = parseInt(end.year);
  let monthFinal = parseInt(end.month + 1);

  let dates = [];

  while (!(year == yearFinal && month == monthFinal)) {
    dates.push(formatStr(year, month));
    month++;
    if (month == 13) {
      month = 1;
      year++;
    }
  }

  dates.push(formatStr(yearFinal, monthFinal));

  return dates;

  function formatStr(year, month) {
    return `${year}-${month < 10 ? `0${month}` : month}`;
  }
}

function getDefaultDateTo() {
  let now = new Date();

  return {
    year: now.getFullYear(),
    month: now.getMonth(),
  };
}

function getYears(yearFrom, yearTo) {
  let years = [];
  for (let year = yearFrom; year <= yearTo; year++) {
    years.push(year);
  }
  return years;
}

function TablePRstatistics() {
  let [state, dispatch] = useReducer(reducer, {
    data: null,
    dataForDashboard: null,
    dataForDashboardBeingDownloading: false,
    dataFiltered: [],
    publishers: [],
    publishersSelected: [],
    publishersSelectedLocked: [],
    publisherTyping: "",
    institutions: [],
    institutionsSelected: [],
    institutionsSelectedLocked: [],
    institutionTyping: "",
    countries: [],
    countriesSelected: [],
    countriesSelectedLocked: [],
    countriesTyping: "",
    expanded: false,
    dateFrom: { year: 2019, month: 0 },
    dateTo: getDefaultDateTo(),
  });
  let {
    publishers,
    publishersSelected,
    publishersSelectedLocked,
    publisherTyping,
    institutions,
    institutionsSelected,
    institutionsSelectedLocked,
    institutionTyping,
    countries,
    countriesSelected,
    countriesSelectedLocked,
    countriesTyping,
    dataFiltered,
    expanded,
    dateFrom,
    dateTo,
    dataForDashboard,
    dataForDashboardBeingDownloading,
    data,
  } = state;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dataForDashboardBeingDownloading) {
      downloadReportData();
    }

    async function downloadReportData() {
      let dataForDashboard = await requestRestricted("/report-data-pr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entryIDs: dataFiltered.map((x) => x.entryID),
          months: generateMonthArray(dateFrom, dateTo),
        }),
      });
      dispatch({
        type: "finish downloading dashboard data",
        payload: { dataForDashboard },
      });
    }
  }, [dataForDashboardBeingDownloading]);

  function changeFromYear(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "From", name: "year", value: e.target.value },
    });
  }

  function changeFromMonth(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "From", name: "month", value: e.target.value },
    });
  }

  function changeToYear(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "To", name: "year", value: e.target.value },
    });
  }

  function changeToMonth(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "To", name: "month", value: e.target.value },
    });
  }

  function getDataForDashboard() {
    dispatch({ type: "start downloading dashboard data" });
  }

  async function getData() {
    var data = await requestRestricted("/institutions-report-pr");
    dispatch({ type: "initialize", payload: { data } });
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {data ? (
        <Fragment>
          <Card className="topControlRow">
            <CardContent className="formControlTop">
              <Button variant="contained" color="primary" disabled={dataFiltered.length == 0 || dataForDashboardBeingDownloading} onClick={getDataForDashboard}>
                Generate Report
              </Button>
              <div className="formControlText">From: </div>
              <FormControl className="formControl">
                <Select value={dateFrom.year} onChange={changeFromYear}>
                  {getYears(2019, dateTo.year).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="formControl">
                <Select value={dateFrom.month} onChange={changeFromMonth}>
                  {months.map((month, i) => (
                    <MenuItem key={month} value={i}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="formControlText">To: </div>
              <FormControl className="formControl">
                <Select value={dateTo.year} onChange={changeToYear}>
                  {getYears(dateFrom.year, getDefaultDateTo().year).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="formControl">
                <Select value={dateTo.month} onChange={changeToMonth}>
                  {months.map((month, i) => (
                    <MenuItem key={month} value={i}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
            <CardContent className="formControlTop">
              <div className="formControlText">Filters: </div>
              <FormControl>
                <Autocomplete
                  multiple
                  options={countries}
                  value={countriesSelected}
                  onChange={(e, value) => {
                    dispatch({
                      type: "select - change",
                      payload: { type: "countriesSelected", value },
                    });
                  }}
                  inputValue={countriesTyping}
                  onInputChange={(e, value) => {
                    dispatch({
                      type: "select - type",
                      payload: { type: "countriesTyping", value },
                    });
                  }}
                  style={{ width: 270, marginRight: 10 }}
                  renderInput={(params) => <TextField {...params} label="Countries" variant="outlined" />}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  multiple
                  options={publishers}
                  value={publishersSelected}
                  onChange={(e, value) => {
                    dispatch({
                      type: "select - change",
                      payload: { type: "publishersSelected", value },
                    });
                  }}
                  inputValue={publisherTyping}
                  onInputChange={(e, value) => {
                    dispatch({
                      type: "select - type",
                      payload: { type: "publisherTyping", value },
                    });
                  }}
                  style={{ width: 400, marginRight: 10 }}
                  renderInput={(params) => <TextField {...params} label="Publishers" variant="outlined" />}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  multiple
                  options={institutions}
                  value={institutionsSelected}
                  onChange={(e, value) => {
                    dispatch({
                      type: "select - change",
                      payload: { type: "institutionsSelected", value },
                    });
                  }}
                  inputValue={institutionTyping}
                  onInputChange={(e, value) => {
                    dispatch({
                      type: "select - type",
                      payload: { type: "institutionTyping", value },
                    });
                  }}
                  style={{ width: 600, marginRight: 10 }}
                  renderInput={(params) => <TextField {...params} label="Institutions" variant="outlined" />}
                />
              </FormControl>
            </CardContent>
            {/* <CardContent>
            <Accordion
              style={{ marginTop: "10px" }}
              disabled={dataFiltered.length == 0}
              expanded={expanded}
              onChange={() => dispatch({ type: "toggle accordion" })}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Check which rows from the table above were selected to generate
                the report
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={dataFiltered}
                  columns={columns}
                  pageSize={15}
                  rowsPerPageOptions={[15]}
                  disableSelectionOnClick
                  autoHeight={true}
                  disableColumnMenu
                  density="compact"
                />
              </AccordionDetails>
            </Accordion>
          </CardContent> */}
          </Card>
          {dataForDashboard ? (
            <DashboardPR
              data={dataForDashboard}
              publishersSelectedLocked={publishersSelectedLocked}
              institutionsSelectedLocked={institutionsSelectedLocked}
              countriesSelectedLocked={countriesSelectedLocked}
            />
          ) : (
            ""
          )}
        </Fragment>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default TablePRstatistics;
