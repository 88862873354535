import React, { useEffect, useReducer, Fragment } from "react";
import { DataGrid, GridToolbar, useGridSlotComponentProps } from "@mui/x-data-grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ENV from "../ENV";
import { requestRestricted } from "./requests";

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      showFirstButton
      showLastButton
    />
  );
}

let columns = [
  {
    field: "entryID",
    headerName: "ID",
    width: 70,
    cellClassName: "small-letters-cell",
  },
  {
    field: "country",
    headerName: "Country",
    width: 110,
  },
  {
    field: "institution",
    headerName: "Institution",
    minWidth: 200,
    flex: 2,
  },
  {
    field: "publisher",
    headerName: "Publisher",
    minWidth: 150,
    flex: 1.5,
  },
  {
    field: "serverStatus",
    headerName: "Status",
    width: 75,
    sortable: false,
    cellClassName: "small-letters-cell",
    renderCell: ({ row }) => {
      if (row.subscribing == "NO") {
        return (
          <span style={{ lineHeight: "16px" }} className="">
            No longer
            <br />
            subscribing
          </span>
        );
      }
      if (row.serverStatus.lastUpdated == undefined) {
        return <span className="redBakground">#N/A</span>;
      } else {
        let dataUpdateServer = new Date(row.serverStatus.lastUpdated).toLocaleDateString("lt-LT");
        let timeUpdateServer = new Date(row.serverStatus.lastUpdated).toLocaleTimeString("lt-LT");
        return (
          <div style={{ lineHeight: "16px" }}>
            {dataUpdateServer}
            <br />
            {row.serverStatus.error ? <span className="redBakground">ERROR</span> : timeUpdateServer}
            <br />
            <a href={`${ENV.apiURL}/unrestricted/server-status-json/${row._id}`} target="_blank">
              (file)
            </a>
          </div>
        );
      }
    },
  },
  {
    field: "serverReports",
    headerName: "Reports",
    width: 85,
    sortable: false,
    cellClassName: "small-letters-cell",
    renderCell: ({ row }) => {
      if (row.subscribing == "NO") {
        return "";
      }
      if (row.serverReports.lastUpdated == undefined) {
        return <span className="redBakground">#N/A</span>;
      } else {
        let dataUpdateServer = new Date(row.serverReports.lastUpdated).toLocaleDateString("lt-LT");
        let timeUpdateServer = new Date(row.serverReports.lastUpdated).toLocaleTimeString("lt-LT");
        return (
          <div style={{ lineHeight: "16px" }}>
            {dataUpdateServer}
            <br />
            {row.serverReports.error ? <span className="redBakground">ERROR</span> : timeUpdateServer}
            <br />
            <a href={`${ENV.apiURL}/unrestricted/server-reports-json/${row._id}`} target="_blank">
              (file)
            </a>
          </div>
        );
      }
    },
  },
];

function reducer(state, action) {
  switch (action.type) {
    case "initialize":
      return initialize(action.payload);
    case "start updating control spreadsheet":
      return startUpdatingControlSpreadsheet();
    case "finish updating control spreadsheet":
      return finishUpdatingControlSpreadsheet(action.payload);
    case "start updating selected status":
      return startUpdatingSelectedStatus();
    case "finish updating selected status":
      return finishUpdatingSelectedStatus();
    case "start updating selected reports list":
      return startUpdatingSelectedReportsList();
    case "finish updating selected reports list":
      return finishUpdatingSelectedReportsList();
    case "remove from selected":
      return removeFromSelected(action.payload);
    case "set selected":
      return setSelected(action.payload);
    case "toggle error only":
      return toggleErrorOnly();
    default:
      throw new Error("wrong action type");
  }

  function finishUpdatingControlSpreadsheet(payload) {
    let { data } = payload;
    return {
      ...state,
      controlBeingUpdated: false,
      _data: data,
      data: transformData(data),
    };
  }

  function startUpdatingControlSpreadsheet() {
    return {
      ...state,
      controlBeingUpdated: true,
    };
  }

  function initialize(payload) {
    let { data } = payload;
    return {
      ...state,
      _data: data,
      data: transformData(data),
    };
  }

  function removeFromSelected(payload) {
    let { id, data } = payload;

    if (!data) {
      return {
        ...state,
        selected: state.selected.filter((x) => x != id),
      };
    }

    const i = state._data.findIndex((x) => x._id == id);
    if (i != -1) {
      state._data[i] = data;
    } else {
      state._data.push(data);
    }

    return {
      ...state,
      data: transformData(state._data),
      selected: state.selected.filter((x) => x != id),
    };
  }

  function startUpdatingSelectedStatus() {
    return {
      ...state,
      statusBeingUpdated: true,
    };
  }

  function finishUpdatingSelectedStatus() {
    return {
      ...state,
      statusBeingUpdated: false,
    };
  }

  function startUpdatingSelectedReportsList() {
    return {
      ...state,
      reportsListBeingUpdated: true,
    };
  }

  function finishUpdatingSelectedReportsList() {
    return {
      ...state,
      reportsListBeingUpdated: false,
    };
  }

  function setSelected(payload) {
    let { selected } = payload;
    return {
      ...state,
      selected,
    };
  }

  function toggleErrorOnly() {
    return {
      ...state,
      errorOnly: !state.errorOnly,
    };
  }
}

function transformData(data) {
  data.forEach((x) => {
    x.id = x._id;
  });
  let allReportsArray = getAllReportsArray(data);
  columns = [
    ...columns,
    ...allReportsArray.reduce(
      (acc, id) => [
        ...acc,
        {
          field: id,
          headerName: id,
          description: id,
          headerClassName: "verticalText",
          width: 25,
          minWidth: 25,
          sortable: false,
          renderCell: ({ row }) => {
            if (row.subscribing == "NO") {
              return "";
            } else {
              return row[id];
            }
          },
        },
      ],
      []
    ),
  ];

  return data.map((x) => {
    allReportsArray.forEach((id) => {
      let obj = x.reports.find((_id) => _id == id);
      x[id] = obj ? 1 : "-";
    });
    return x;
  });

  function getAllReportsArray(data) {
    let array = data.reduce((acc, cur) => {
      cur.reports.forEach((id) => {
        if (typeof id == "string" && acc.indexOf(id) == -1) acc.push(id);
      });
      return acc;
    }, []);
    array.sort();
    return array;
  }
}

function filterDataErrorOnly(data) {
  return data.filter((row) => {
    return (
      row.serverReports.lastUpdated == undefined ||
      row.serverReports.error ||
      row.serverStatus.lastUpdated == undefined ||
      row.serverStatus.error
    );
  });
}

export default function TableStatus(props) {
  const { admin } = props;
  let [state, dispatch] = useReducer(reducer, {
    _data: null,
    data: null,
    controlBeingUpdated: false,
    statusBeingUpdated: false,
    reportsListBeingUpdated: false,
    selected: [],
    errorOnly: false,
  });
  let { data, controlBeingUpdated, statusBeingUpdated, reportsListBeingUpdated, selected, errorOnly } = state;
  let _data = data;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (statusBeingUpdated) {
      if (selected.length > 0) {
        tick(selected[0]);
      } else {
        dispatch({ type: "finish updating selected status" });
      }
    }
    async function tick(id) {
      let data = await requestRestricted(`/update-status/${id}`);
      dispatch({ type: "remove from selected", payload: { id, data } });
    }
  });

  useEffect(() => {
    if (reportsListBeingUpdated) {
      if (selected.length > 0) {
        tick(selected[0]);
      } else {
        dispatch({ type: "finish updating selected reports list" });
      }
    }
    async function tick(id) {
      let row = _data.find((x) => x._id == id);
      if (!row.serverStatus.error) {
        let data = await requestRestricted(`/update-reports/${id}`);
        dispatch({ type: "remove from selected", payload: { id, data } });
      } else {
        dispatch({
          type: "remove from selected",
          payload: { id },
        });
      }
    }
  });

  function setSelected(selected) {
    dispatch({ type: "set selected", payload: { selected } });
  }

  async function getData() {
    var data = await requestRestricted("/institutions-control");
    dispatch({ type: "initialize", payload: { data } });
  }

  async function updateControlSpreadsheet() {
    dispatch({ type: "start updating control spreadsheet" });
    let data = await requestRestricted("/update-control-spreadsheet");
    dispatch({
      type: "finish updating control spreadsheet",
      payload: { data },
    });
  }

  function updateSelectedStatus() {
    dispatch({ type: "start updating selected status" });
  }

  function updateSelectedReportsList() {
    dispatch({ type: "start updating selected reports list" });
  }

  function toggleErrorOnly() {
    dispatch({ type: "toggle error only" });
  }

  // const [pageSize, setPageSize] = React.useState(10);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {data ? (
        <Fragment>
          <Card className="topControlRow">
            <CardContent>
              {admin && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={controlBeingUpdated}
                  onClick={() => {
                    if (!controlBeingUpdated) updateControlSpreadsheet();
                  }}
                >
                  Update Control Spreadsheet
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={statusBeingUpdated || reportsListBeingUpdated || selected.length == 0}
                onClick={updateSelectedStatus}
              >
                Update Server Status
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={reportsListBeingUpdated || statusBeingUpdated || selected.length == 0}
                onClick={updateSelectedReportsList}
              >
                Update Reports List
              </Button>
              <FormControlLabel
                control={<Checkbox checked={errorOnly} onChange={toggleErrorOnly} />}
                label="Error only"
              />
            </CardContent>
          </Card>

          <DataGrid
            rows={errorOnly ? filterDataErrorOnly(data) : data}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            // pageSize={pageSize}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
            checkboxSelection={admin}
            disableSelectionOnClick
            autoHeight={true}
            // disableDensitySelector
            disableColumnMenu
            components={{
              Toolbar: GridToolbar,
              Pagination: CustomPagination,
            }}
            onSelectionModelChange={setSelected}
            selectionModel={selected}
          />
        </Fragment>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
