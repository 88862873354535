import React, { Fragment, useReducer, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import papa from "papaparse";
import Pagination from "@material-ui/lab/Pagination";
import { DataGrid, useGridSlotComponentProps } from "@mui/x-data-grid";

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      siblingCount={4}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      size="small"
      showFirstButton
      showLastButton
    />
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "initialize":
      return initialize(action.payload);
    case "switch metric":
      return switchMetric(action.payload);
    case "change sum over":
      return changeSumOver(action.payload);
  }

  function changeSumOver(payload) {
    let { sumOver } = payload;
    return {
      ...state,
      sumOver,
    };
  }

  function switchMetric(payload) {
    let { metric } = payload;

    return {
      ...state,
      metric,
    };
  }

  function initialize(payload) {
    let { data } = payload;

    if (!data.error) {
      var allMetricTypes = data.allMetricTypes;
      var metric = allMetricTypes.indexOf("Total_Item_Requests") != -1 ? "Total_Item_Requests" : allMetricTypes[0];
      var _data = data.data;
    } else {
      var allMetricTypes = [];
      var metric = null;
    }

    return {
      ...state,
      data: _data,
      error: data.error !== undefined,
      allMetricTypes,
      metric,
    };
  }
}

function getNotSummedOver({ metric, data }) {
  var rowTotals = data.map((x) => {
    return x.months.reduce(
      (acc, cur) => {
        if (typeof cur.data != "object") {
          if (!acc.unknowns && cur.data == "unknown") {
            acc.unknowns = true;
          }
        } else {
          var obj = cur.data.find((x) => x.metric == metric);

          if (obj) {
            acc.value += obj.count;
          }
        }
        return acc;
      },
      {
        unknowns: false,
        value: 0,
      }
    );
  });

  var colTotals = [];
  for (var i = 0; i < data[0].months.length; i++) {
    colTotals.push(
      data.reduce(
        (acc, x) => {
          var cur = x.months[i];

          if (typeof cur.data != "object") {
            if (!acc.unknowns && cur.data == "unknown") {
              acc.unknowns = true;
            }
          } else {
            var obj = cur.data.find((x) => x.metric == metric);

            if (obj) {
              acc.value += obj.count;
            }
          }
          return acc;
        },
        {
          unknowns: false,
          value: 0,
          key: data[0].months[i].month,
        }
      )
    );
  }

  return { rowTotals, colTotals };
}

function getSummedOver({ metric, data, sumOver }) {
  var uniques = data.map((x) => x[sumOver]).filter((x, i, arr) => arr.indexOf(x) == i);

  var _data = uniques.map((x) => {
    return data
      .filter((y) => y[sumOver] == x)
      .reduce((acc, cur, i) => {
        if (i == 0) {
          return {
            [sumOver]: cur[sumOver],
            months: cur.months.map((x) => {
              var toReturn = {
                month: x.month,
              };
              if (typeof x.data != "object") {
                if (x.data == "unknown") {
                  toReturn.unknowns = true;
                } else {
                  toReturn.unknowns = false;
                }
                toReturn.value = 0;
              } else {
                toReturn.unknowns = false;
                var obj = x.data.find((x) => x.metric == metric);

                if (obj) {
                  toReturn.value = obj.count;
                } else {
                  toReturn.value = 0;
                }
              }
              return toReturn;
            }),
          };
        } else {
          cur.months.forEach((x, i) => {
            if (typeof x.data != "object") {
              if (!acc.months[i].unknowns && x.data == "unknown") {
                acc.months[i].unknowns = true;
              }
            } else {
              var obj = x.data.find((x) => x.metric == metric);

              if (obj) {
                acc.months[i].value += obj.count;
              }
            }
          });
          return acc;
        }
      }, null);
  });

  var rowTotals = _data.map((x) => {
    return x.months.reduce(
      (acc, cur) => {
        if (!acc.unknowns && cur.unknowns) acc.unknowns = true;
        if (cur.value) acc.value += cur.value;

        return acc;
      },
      {
        unknowns: false,
        value: 0,
      }
    );
  });

  var colTotals = [];
  for (var i = 0; i < data[0].months.length; i++) {
    colTotals.push(
      _data.reduce(
        (acc, x) => {
          var cur = x.months[i];

          if (!acc.unknowns && cur.unknowns) acc.unknowns = true;
          if (cur.value) acc.value += cur.value;

          return acc;
        },
        {
          unknowns: false,
          value: 0,
          key: _data[0].months[i].month,
        }
      )
    );
  }

  return { _data, rowTotals, colTotals };
}

function getTableDataNotSummedOver(metric, data, rowTotals, colTotals) {
  const tableData = [];

  tableData.push(["Country", "Institution", "Publisher", "Total", ...data[0].months.map(({ month }) => month)]);

  data.forEach((x, i) => {
    tableData.push([
      x.country,
      x.institution,
      x.publisher,
      {
        unknowns: rowTotals[i].unknowns,
        value: rowTotals[i].value,
      },
      ...x.months.map((x) => {
        let value;
        if (typeof x.data != "object") {
          switch (x.data) {
            case "no usage":
              value = 0;
              break;
            case "unknown":
              value = "n/a";
              break;
            default:
              value = x.data;
          }
        } else {
          var obj = x.data.find((x) => x.metric == metric);
          if (!obj) {
            value = 0;
          } else {
            value = obj.count;
          }
        }
        return {
          unknowns: false,
          value,
        };
      }),
    ]);
  });

  tableData.push([
    "Total",
    "",
    "",
    "",
    ...colTotals.map((x) => ({
      unknowns: x.unknowns,
      value: x.value,
    })),
  ]);

  return tableData;
}

function getTableDataSummedOver(sumOver, data, rowTotals, colTotals) {
  const tableData = [];

  tableData.push([
    sumOver.charAt(0).toUpperCase() + sumOver.slice(1),
    "",
    "",
    "Total",
    ...data[0].months.map(({ month }) => month),
  ]);

  data.forEach((x, i) => {
    tableData.push([
      x[sumOver],
      "",
      "",
      {
        unknowns: rowTotals[i].unknowns,
        value: rowTotals[i].value,
      },
      ...x.months.map((x) => ({
        unknowns: x.unknowns,
        value: x.value,
      })),
    ]);
  });

  tableData.push([
    "Total",
    "",
    "",
    "",
    ...colTotals.map((x) => ({
      unknowns: x.unknowns,
      value: x.value,
    })),
  ]);

  return tableData;
}

function TableFullDataGrid({ metric, tableData }) {
  const header = tableData[0];
  const data = tableData.slice(1, -1);
  const lastRow = tableData[tableData.length - 1];

  const dataForDataGrid = data.map((x, i) => {
    x.id = i;
    return x;
  });

  function getVal(params) {
    if (typeof params.value == "object") {
      return params.value.value == "n/a" ? -1 : params.value.value;
    }
    return params.value;
  }

  const columns = header.map((x, i) => {
    const column = {
      field: i + "",
      sortComparator: (v1, v2, param1, param2) => {
        if (typeof param1.value == "object") {
          return getVal(param1) - getVal(param2);
        } else {
          if (param1.value > param2.value) return 1;
          if (param1.value < param2.value) return -1;
          return 0;
        }
      },
      valueFormatter: (params) => (typeof params.value == "object" ? params.value.value : params.value),
    };
    if (x.includes("20") || x == "Total") {
      column.headerName = (
        <div className="top-row-cell">
          {x}
          <br />
          &#x2211;: {lastRow[i].value}
        </div>
      );
      column.headerClassName = "headerDashboardPR";
      column.cellClassName = "cellDashboardPR";
    } else {
      column.headerName = x;
      column.minWidth = 250;
    }
    return column;
  });
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DataGrid
        rows={dataForDataGrid}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        headerHeight={70}
        autoHeight={true}
        pageSize={20}
        pagination
        components={{
          Pagination: CustomPagination,
        }}
        density="compact"
      />
    </div>
  );
}

function DashboardPR(props) {
  let [state, dispatch] = useReducer(reducer, {
    data: null,
    error: props.error !== undefined,
    allMetricTypes: [],
    metric: null,
    sumOver: "none",
  });
  let { data, allMetricTypes, error, metric, sumOver } = state;
  let { publishersSelectedLocked, institutionsSelectedLocked, countriesSelectedLocked } = props;

  useEffect(() => {
    dispatch({ type: "initialize", payload: { data: props.data } });
  }, [props]);

  const changeMetric = (event) => {
    dispatch({
      type: "switch metric",
      payload: { metric: event.target.value },
    });
  };

  function changeSumOver(e) {
    dispatch({
      type: "change sum over",
      payload: { sumOver: e.target.value },
    });
  }

  return (
    <Card className="marginTop">
      <CardContent>
        {(() => {
          if (error) {
            return (
              <Alert variant="outlined" severity="error">
                Server error: {error}
              </Alert>
            );
          }

          if (metric) {
            if (sumOver == "none") {
              var { rowTotals, colTotals } = getNotSummedOver({ metric, data });
              var tableData = getTableDataNotSummedOver(metric, data, rowTotals, colTotals);
            } else {
              var { _data, rowTotals, colTotals } = getSummedOver({
                metric,
                data,
                sumOver,
              });
              var tableData = getTableDataSummedOver(sumOver, _data, rowTotals, colTotals);
            }

            const csv = papa.unparse(tableData.map((row) => row.map((x) => (typeof x === "object" ? x.value : x))));
            const blob = new Blob([csv], {
              type: "text/csv",
            });
            const blobUrl = URL.createObjectURL(blob);

            return (
              <Fragment>
                <div className="formControlTop">
                  <Typography variant="h5" gutterBottom className="reportTitle">
                    Report:
                  </Typography>
                  <div className="formControlText">Metric - </div>
                  <FormControl className="formControl">
                    <Select defaultValue={allMetricTypes[0]} value={metric} onChange={changeMetric} label="Metric">
                      {allMetricTypes.map((x) => (
                        <MenuItem key={x} value={x}>
                          {x.replace(/_/g, " ")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="formControlText">Sum over - </div>
                  <FormControl component="fieldset" className="formControl">
                    <RadioGroup value={sumOver} onChange={changeSumOver} row>
                      <FormControlLabel value="none" control={<Radio />} label="None" />
                      <FormControlLabel value="publisher" control={<Radio />} label="Publishers" />
                      <FormControlLabel value="institution" control={<Radio />} label="Institutions" />
                      <FormControlLabel value="country" control={<Radio />} label="Countries" />
                    </RadioGroup>
                  </FormControl>
                  <a href={blobUrl} download={`report_${metric}.csv`}>
                    <Button variant="contained" color="primary">
                      Download CSV
                    </Button>
                  </a>
                </div>
                {publishersSelectedLocked.length > 0 ? (
                  <div style={{ marginTop: "10px" }}>
                    <div className="formControlText">Selected: </div>
                    <FormControl>
                      <div className="chipList">
                        {publishersSelectedLocked.map((x) => (
                          <Chip key={x} label={x} style={{ marginLeft: "5px" }} />
                        ))}
                      </div>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                {institutionsSelectedLocked.length > 0 ? (
                  <div style={{ marginTop: "10px" }}>
                    <div className="formControlText">Selected: </div>
                    <FormControl>
                      <div className="chipList">
                        {institutionsSelectedLocked.map((x) => (
                          <Chip key={x} label={x} style={{ marginLeft: "5px" }} />
                        ))}
                      </div>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                {countriesSelectedLocked.length > 0 ? (
                  <div style={{ marginTop: "10px" }}>
                    <div className="formControlText">Selected: </div>
                    <FormControl>
                      <div className="chipList">
                        {countriesSelectedLocked.map((x) => (
                          <Chip key={x} label={x} style={{ marginLeft: "5px" }} />
                        ))}
                      </div>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                <TableFullDataGrid metric={metric} tableData={tableData} />
              </Fragment>
            );
          }

          return "";
        })()}
      </CardContent>
    </Card>
  );
}

export default DashboardPR;
