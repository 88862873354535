import React, { useEffect, useReducer, Fragment } from "react";
import { DataGrid, GridToolbar, useGridSlotComponentProps } from "@mui/x-data-grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import InfoIcon from "@material-ui/icons/Info";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";

import * as d3 from "d3";

import ENV from "../../ENV";
import { requestRestricted } from "../requests";

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      siblingCount={4}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      size="small"
      showFirstButton
      showLastButton
    />
  );
}

const columns = [
  {
    field: "entryID",
    headerName: "ID",
    width: 70,
    cellClassName: "small-letters-cell",
  },
  {
    field: "country",
    headerName: "Country",
    width: 110,
  },
  {
    field: "institution",
    headerName: "Institution",
    minWidth: 200,
    flex: 2,
  },
  {
    field: "publisher",
    headerName: "Publisher",
    minWidth: 150,
    flex: 1.5,
  },
  {
    field: "details",
    headerName: "Details",
    width: 110,
    sortable: false,
    renderCell: InfoCell,
  },
  {
    field: "line",
    headerName: "Downloaded",
    minWidth: 600,
    sortable: false,
    renderCell: LineCell,
  },
];

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let OPTIONS = {
  width: 600,
  pad: 20,
  height: 50,
  colours: {
    "Usage Not Ready (3031)": d3.schemeTableau10[1],
    error: d3.schemeTableau10[2],
    "error - other": d3.schemeTableau10[2],
    downloaded: d3.schemeTableau10[4],
    "queued (1011)": d3.schemeTableau10[5],
    "No Usage (3030)": d3.schemeTableau10[6],
    "not downloaded": d3.schemeTableau10[9],
    "No Subscription": d3.schemeTableau10[7],
  },
};

function amendDataSingle(row) {
  row.id = row._id;
  let PR = row.reports.find((x) => x.id == "PR");

  let date = new Date();
  let months = generateMonthArray(
    {
      year: 2019,
      month: 0,
    },
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    }
  );

  months.pop();
  months.pop();

  months = months.map((month) => {
    let existing = PR.months.find((x) => x.month == month);

    if (existing) return existing;

    return { month, status: "not downloaded" };
  });

  PR.months = months;

  row.PR = PR;

  return row;
}

function amendData(data) {
  return data.map(amendDataSingle);
}

function generateMonthArray(start, end) {
  let year = parseInt(start.year);
  let month = parseInt(start.month + 1);

  if (month == 13) {
    month = 1;
    year++;
  }

  let yearFinal = parseInt(end.year);
  let monthFinal = parseInt(end.month + 1);

  if (monthFinal == 13) {
    monthFinal = 1;
    yearFinal++;
  }

  let dates = [];

  while (!(year == yearFinal && month == monthFinal)) {
    dates.push(formatStr(year, month));
    month++;
    if (month == 13) {
      month = 1;
      year++;
    }
  }

  dates.push(formatStr(yearFinal, monthFinal));

  return dates;

  function formatStr(year, month) {
    return `${year}-${month < 10 ? `0${month}` : month}`;
  }
}

function wait(x) {
  return new Promise((res) => setTimeout(res, x));
}

function reducer(state, action) {
  switch (action.type) {
    case "initialize":
      return initialize(action.payload);
    case "set selected":
      return setSelected(action.payload);
    case "start updating selected reports":
      return startUpdatingSelectedReports();
    case "finish updating selected reports":
      return finishUpdatingSelectedReports();
    case "start updating single":
      return startUpdatingSingle(action.payload);
    case "finish updating single":
      return finishUpdatingSingle(action.payload);
    case "remove month":
      return removeMonth(action.payload);
    case "remove report":
      return removeReport(action.payload);
    case "change date":
      return setDate(action.payload);
    case "open row":
      return openRow(action.payload);
    case "close row":
      return closeRow();
    case "toggle only queued 1011":
      return toggleOnlyQueued1011();
    case "toggle only no usage 3030":
      return toggleOnlyNoUsage3030();
    case "toggle only not ready 3031":
      return toggleOnlyNotReady3031();
    case "toggle only too long ago 3031":
      return toggleOnlyTooLongAgo3031();
    case "toggle only no longer available 3032":
      return toggleOnlyNoLongerAvailable3032();
    case "toggle only not authorized 2000/2010":
      return toggleOnlyNotAuthorized2000_2010();
    case "toggle only api key 2020":
      return toggleOnlyApiKey2020();
    case "toggle only dates 3020":
      return toggleOnlyDates3020();
    case "toggle only error 0000":
      return toggleOnlyError0000();
  }

  function startUpdatingSingle(payload) {
    let { id, month } = payload;
    return {
      ...state,
      reportBeingUpdated: true,
      singleReportUpdate: { id, month },
    };
  }

  function finishUpdatingSingle(payload) {
    let { data, entryID } = payload;

    const dataAmended = amendDataSingle(data);
    const i = state.data.findIndex((x) => x.entryID == entryID);
    state.data[i] = dataAmended;

    return {
      ...state,
      reportBeingUpdated: false,
      singleReportUpdate: null,
    };
  }

  function openRow(payload) {
    let { idOpenedRow } = payload;
    return {
      ...state,
      idOpenedRow,
    };
  }

  function closeRow() {
    return {
      ...state,
      idOpenedRow: null,
    };
  }

  function setDate(payload) {
    let { suffix, name, value } = payload;

    if (
      suffix == "From" &&
      name == "year" &&
      (value > state.dateTo.year || (value == state.dateTo.year && state.dateFrom.month > state.dateTo.month))
    ) {
      return state;
    }

    if (
      suffix == "To" &&
      name == "year" &&
      (value < state.dateFrom.year || (value == state.dateFrom.year && state.dateFrom.month > state.dateTo.month))
    ) {
      return state;
    }

    if (suffix == "From" && name == "month" && state.dateFrom.year == state.dateTo.year && value > state.dateTo.month) {
      return state;
    }

    if (suffix == "To" && name == "month" && state.dateFrom.year == state.dateTo.year && value < state.dateFrom.month) {
      return state;
    }

    let stateNew = {
      ...state,
      [`date${suffix}`]: {
        ...state[`date${suffix}`],
        [name]: value,
      },
    };

    return {
      ...stateNew,
      monthsBeingDownloaded: generateMonthArray(stateNew.dateFrom, stateNew.dateTo),
    };
  }

  function removeReport(payload) {
    let { id } = payload;
    return {
      ...state,
      selected: state.selected.filter((x) => x != id),
      monthsBeingDownloaded: generateMonthArray(state.dateFrom, state.dateTo),
    };
  }

  function removeMonth(payload) {
    let { month, data, entryID } = payload;

    const dataAmended = amendDataSingle(data);
    const i = state.data.findIndex((x) => x.entryID == entryID);
    state.data[i] = dataAmended;

    return {
      ...state,
      monthsBeingDownloaded: state.monthsBeingDownloaded.filter((x) => x != month),
    };
  }

  function finishUpdatingSelectedReports() {
    return {
      ...state,
      reportsBeingUpdated: false,
    };
  }

  function startUpdatingSelectedReports() {
    return {
      ...state,
      reportsBeingUpdated: true,
    };
  }

  function setSelected(payload) {
    let { selected } = payload;
    return {
      ...state,
      selected,
    };
  }

  function initialize(payload) {
    let { data } = payload;

    return {
      ...state,
      data: amendData(data),
      monthsBeingDownloaded: generateMonthArray(state.dateFrom, state.dateTo),
    };
  }

  function toggleOnlyQueued1011() {
    return {
      ...state,
      onlyQueued1011: !state.onlyQueued1011,
    };
  }

  function toggleOnlyNoUsage3030() {
    return {
      ...state,
      onlyNoUsage3030: !state.onlyNoUsage3030,
    };
  }

  function toggleOnlyNotReady3031() {
    return {
      ...state,
      onlyNotReady3031: !state.onlyNotReady3031,
    };
  }

  function toggleOnlyTooLongAgo3031() {
    return {
      ...state,
      onlyTooLongAgo3031: !state.onlyTooLongAgo3031,
    };
  }

  function toggleOnlyNoLongerAvailable3032() {
    return {
      ...state,
      onlyNoLongerAvailable3032: !state.onlyNoLongerAvailable3032,
    };
  }

  function toggleOnlyNotAuthorized2000_2010() {
    return {
      ...state,
      onlyNotAuthorized2000_2010: !state.onlyNotAuthorized2000_2010,
    };
  }

  function toggleOnlyApiKey2020() {
    return {
      ...state,
      onlyApiKey2020: !state.onlyApiKey2020,
    };
  }

  function toggleOnlyDates3020() {
    return {
      ...state,
      onlyDates3020: !state.onlyDates3020,
    };
  }

  function toggleOnlyError0000() {
    return {
      ...state,
      onlyError0000: !state.onlyError0000,
    };
  }
}

function getDefaultDateTo() {
  let now = new Date();

  return {
    year: now.getFullYear(),
    month: now.getMonth(),
  };
}

function getYears(yearFrom, yearTo) {
  let years = [];
  for (let year = yearFrom; year <= yearTo; year++) {
    years.push(year);
  }
  return years;
}

function LineCell(props) {
  let { row } = props;

  useEffect(() => {
    drawLines(row);
  });

  function drawLines(row) {
    let { width, height, pad, colours } = OPTIONS;

    let segmentWidth = (width - 2 * pad) / row.PR.months.length;

    let svg = d3.select(`#a${row._id}`);

    svg.attr("width", width).attr("height", height);

    let update = svg.selectAll("line").data(row.PR.months);

    let enter = update
      .enter()
      .append("line")
      .attr("stroke-width", 6)
      .attr("y1", height / 2)
      .attr("y2", height / 2);

    update
      .merge(enter)
      .attr("stroke", ({ status }) => colours[status])
      .attr("x1", (d, i) => pad + i * segmentWidth)
      .attr("x2", (d, i) => pad + (i + 1) * segmentWidth);
  }

  return <svg id={`a${row._id}`} />;
}

function InfoCell(props) {
  let {
    row: { openRow, id, reportsBeingUpdated },
  } = props;

  return (
    <IconButton
      variant="contained"
      color={reportsBeingUpdated ? "disabled" : "primary"}
      onClick={() => {
        if (!reportsBeingUpdated) {
          openRow(id);
        }
      }}
    >
      <InfoIcon color={reportsBeingUpdated ? "disabled" : "inherit"} />
    </IconButton>
  );
}

const ModalInner = React.forwardRef(function (props, ref) {
  let { data, updateSingle, reportsBeingUpdated, reportBeingUpdated, admin } = props;

  return (
    <Card className="modal-inner">
      <CardContent>
        <Typography gutterBottom>
          <p fontWeight="bold">
            {data.country} - {data.institution}
            <br />
            {data.publisher}
            <br />
          </p>
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableRowSmall">Month</TableCell>
                <TableCell className="tableRowSmall">Last Updated</TableCell>
                <TableCell className="tableRowSmall">Status</TableCell>
                <TableCell className="tableRowSmall">Code</TableCell>
                <TableCell className="tableRowSmall">File</TableCell>
                {admin && <TableCell className="tableRowSmall">Download</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.PR.months
                .reduce((acc, cur) => {
                  acc.unshift(cur);
                  return acc;
                }, [])
                .map((row) => (
                  <TableRow key={row.month}>
                    <TableCell className="tableRowSmall">{row.month}</TableCell>
                    <TableCell className="tableRowSmall">{row.lastUpdated ? row.lastUpdated : ""}</TableCell>
                    <TableCell className="tableRowSmall">{row.status}</TableCell>
                    <TableCell className="tableRowSmall">{row.statusCode}</TableCell>
                    <TableCell className="tableRowSmall">
                      <IconButton
                        className="tableRowSmall"
                        disabled={row.status == "not downloaded"}
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={`${ENV.apiURL}/unrestricted/server-report/PR/${data._id}/${row.month}`}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </TableCell>
                    {admin && (
                      <TableCell className="tableRowSmall">
                        <IconButton
                          className="tableRowSmall"
                          variant="contained"
                          color="primary"
                          onClick={() => updateSingle(data._id, row.month)}
                          disabled={reportsBeingUpdated || reportBeingUpdated}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
});

function filterDataOnlyConditions(
  data,
  onlyQueued1011,
  onlyNoUsage3030,
  onlyNotReady3031,
  onlyTooLongAgo3031,
  onlyNoLongerAvailable3032,
  onlyNotAuthorized2000_2010,
  onlyApiKey2020,
  onlyDates3020,
  onlyError0000
) {
  return data.filter((row) => {
    let test = true;

    if (onlyQueued1011) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 1011);
      test = test && exists;
    }

    if (onlyNoUsage3030) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 3030);
      test = test && exists;
    }

    if (onlyApiKey2020) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 2020);
      test = test && exists;
    }

    if (onlyDates3020) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 3020);
      test = test && exists;
    }

    if (onlyNotAuthorized2000_2010) {
      var exists = row.PR.months.some(
        (month) => month.statusCode !== undefined && (month.statusCode == 2000 || month.statusCode == 2010)
      );
      test = test && exists;
    }

    if (onlyNoLongerAvailable3032) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 3032);
      test = test && exists;
    }

    if (onlyNotReady3031) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 3031);
      test = test && exists;
    }

    if (onlyTooLongAgo3031) {
      var exists = row.PR.months.some((month) => month.statusCode !== undefined && month.statusCode == 3031);
      test = test && exists;
    }

    if (onlyError0000) {
      var exists = row.PR.months.some(
        (month) =>
          month.error !== undefined &&
          month.error &&
          !(
            month.statusCode == 2000 ||
            month.statusCode == 2010 ||
            month.statusCode == 2020 ||
            month.statusCode == 3020
          )
      );
      test = test && exists;
    }

    return test;
  });
}

export default function TablePR(props) {
  const { admin } = props;
  let [state, dispatch] = useReducer(reducer, {
    data: null,
    reportsBeingUpdated: false,
    reportBeingUpdated: false,
    singleReportUpdate: null,
    monthsBeingDownloaded: [],
    dateFrom: { year: 2021, month: 0 },
    dateTo: getDefaultDateTo(),
    selected: [],
    idOpenedRow: null,
    onlyQueued1011: false,
    onlyNoUsage3030: false,
    onlyNotReady3031: false,
    onlyTooLongAgo3031: false,
    onlyNoLongerAvailable3032: false,
    onlyNotAuthorized2000_2010: false,
    onlyApiKey2020: false,
    onlyDates3020: false,
    onlyError0000: false,
  });
  let {
    data,
    reportsBeingUpdated,
    reportBeingUpdated,
    singleReportUpdate,
    monthsBeingDownloaded,
    dateFrom,
    dateTo,
    selected,
    idOpenedRow,
    onlyQueued1011,
    onlyNoUsage3030,
    onlyNotReady3031,
    onlyTooLongAgo3031,
    onlyNoLongerAvailable3032,
    onlyNotAuthorized2000_2010,
    onlyApiKey2020,
    onlyDates3020,
    onlyError0000,
  } = state;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (reportsBeingUpdated) {
      if (selected.length > 0) {
        if (monthsBeingDownloaded.length > 0) {
          tickMonth(selected[0], monthsBeingDownloaded[0]);
        } else {
          tickReport(selected[0]);
        }
      } else {
        dispatch({ type: "finish updating selected reports" });
      }
    }

    async function tickReport(id) {
      dispatch({ type: "remove report", payload: { id } });
    }

    async function tickMonth(id, month) {
      let obj = data.find((x) => x._id == id);
      let _data = await requestRestricted(`/update-report-pr/${obj.entryID}.${month}`);
      await wait(3000);
      dispatch({ type: "remove month", payload: { month, data: _data, entryID: obj.entryID } });
    }
  });

  useEffect(() => {
    if (singleReportUpdate) {
      downloadSingle(singleReportUpdate);
    }

    async function downloadSingle(singleReportUpdate) {
      let { id, month } = singleReportUpdate;
      let obj = data.find((x) => x._id == id);
      let _data = await requestRestricted(`/update-report-pr/${obj.entryID}.${month}`);
      dispatch({ type: "finish updating single", payload: { data: _data, entryID: obj.entryID } });
    }
  }, [singleReportUpdate]);

  function openRow(idOpenedRow) {
    dispatch({ type: "open row", payload: { idOpenedRow } });
  }

  function closeRow() {
    dispatch({ type: "close row" });
  }

  async function getData() {
    var data = await requestRestricted("/institutions-report-pr");
    dispatch({ type: "initialize", payload: { data } });
  }

  function updateSelectedReports() {
    dispatch({ type: "start updating selected reports" });
  }

  function changeFromYear(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "From", name: "year", value: e.target.value },
    });
  }

  function changeFromMonth(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "From", name: "month", value: e.target.value },
    });
  }

  function changeToYear(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "To", name: "year", value: e.target.value },
    });
  }

  function changeToMonth(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "To", name: "month", value: e.target.value },
    });
  }

  function setSelected(selected) {
    dispatch({ type: "set selected", payload: { selected } });
  }

  function updateSingle(id, month) {
    dispatch({ type: "start updating single", payload: { id, month } });
  }

  function toggleOnlyQueued1011() {
    dispatch({ type: "toggle only queued 1011" });
  }

  function toggleOnlyNoUsage3030() {
    dispatch({ type: "toggle only no usage 3030" });
  }

  function toggleOnlyNotReady3031() {
    dispatch({ type: "toggle only not ready 3031" });
  }

  function toggleOnlyTooLongAgo3031() {
    dispatch({ type: "toggle only too long ago 3031" });
  }

  function toggleOnlyNoLongerAvailable3032() {
    dispatch({ type: "toggle only no longer available 3032" });
  }

  function toggleOnlyNotAuthorized2000_2010() {
    dispatch({ type: "toggle only not authorized 2000/2010" });
  }

  function toggleOnlyApiKey2020() {
    dispatch({ type: "toggle only api key 2020" });
  }

  function toggleOnlyDates3020() {
    dispatch({ type: "toggle only dates 3020" });
  }

  function toggleOnlyError0000() {
    dispatch({ type: "toggle only error 0000" });
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {data ? (
        <Fragment>
          <Card className="topControlRow">
            <CardContent className="formControlTop">
              {admin && (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={reportsBeingUpdated || reportBeingUpdated || selected.length == 0}
                    onClick={updateSelectedReports}
                  >
                    Download Reports
                  </Button>
                  <div className="formControlText">From: </div>
                  <FormControl className="formControl">
                    <Select
                      disabled={reportsBeingUpdated || reportBeingUpdated}
                      value={dateFrom.year}
                      onChange={changeFromYear}
                    >
                      {getYears(2019, dateTo.year).map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formControl">
                    <Select
                      disabled={reportsBeingUpdated || reportBeingUpdated}
                      value={dateFrom.month}
                      onChange={changeFromMonth}
                    >
                      {months.map((month, i) => (
                        <MenuItem key={month} value={i}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="formControlText">To: </div>
                  <FormControl className="formControl">
                    <Select
                      disabled={reportsBeingUpdated || reportBeingUpdated}
                      value={dateTo.year}
                      onChange={changeToYear}
                    >
                      {getYears(dateFrom.year, getDefaultDateTo().year).map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formControl">
                    <Select
                      disabled={reportsBeingUpdated || reportBeingUpdated}
                      value={dateTo.month}
                      onChange={changeToMonth}
                    >
                      {months.map((month, i) => (
                        <MenuItem key={month} value={i}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Fragment>
              )}
              <div className="formControlText">
                Colour
                <br />
                map:
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["downloaded"] }}></div>
              <div className="errorText">OK</div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["queued (1011)"] }}></div>
              <div className="errorText">
                Queued
                <br />
                1011
                <br />
                <Checkbox checked={onlyQueued1011} onChange={toggleOnlyQueued1011} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["No Usage (3030)"] }}></div>
              <div className="errorText">
                No
                <br />
                Usage
                <br />
                3030
                <br />
                <Checkbox checked={onlyNoUsage3030} onChange={toggleOnlyNoUsage3030} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["Usage Not Ready (3031)"] }}></div>
              <div className="errorText">
                Not
                <br />
                Ready
                <br />
                3031
                <br />
                <Checkbox checked={onlyNotReady3031} onChange={toggleOnlyNotReady3031} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["No Subscription"] }}></div>
              <div className="errorText">
                Too
                <br />
                long ago
                <br />
                3031
                <br />
                <Checkbox checked={onlyTooLongAgo3031} onChange={toggleOnlyTooLongAgo3031} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["No Subscription"] }}></div>
              <div className="errorText">
                No
                <br />
                Longer
                <br />
                3032
                <br />
                <Checkbox checked={onlyNoLongerAvailable3032} onChange={toggleOnlyNoLongerAvailable3032} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["error"] }}></div>
              <div className="errorText">
                Not
                <br />
                Authorized
                <br />
                2000/2010
                <br />
                <Checkbox checked={onlyNotAuthorized2000_2010} onChange={toggleOnlyNotAuthorized2000_2010} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["error"] }}></div>
              <div className="errorText">
                APIKey
                <br />
                2020
                <br />
                <Checkbox checked={onlyApiKey2020} onChange={toggleOnlyApiKey2020} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["error"] }}></div>
              <div className="errorText">
                Dates
                <br />
                3020
                <br />
                <Checkbox checked={onlyDates3020} onChange={toggleOnlyDates3020} />
              </div>

              <div className="errorBox" style={{ backgroundColor: OPTIONS.colours["error"] }}></div>
              <div className="errorText">
                Error
                <br />
                0000
                <br />
                <Checkbox checked={onlyError0000} onChange={toggleOnlyError0000} />
              </div>
            </CardContent>
          </Card>
          <Card className="marginTop">
            <CardContent>
              <DataGrid
                rows={filterDataOnlyConditions(
                  data,
                  onlyQueued1011,
                  onlyNoUsage3030,
                  onlyNotReady3031,
                  onlyTooLongAgo3031,
                  onlyNoLongerAvailable3032,
                  onlyNotAuthorized2000_2010,
                  onlyApiKey2020,
                  onlyDates3020,
                  onlyError0000
                ).map((x) => ({
                  ...x,
                  openRow,
                  reportsBeingUpdated,
                }))}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[25, 50, 100]}
                pagination
                checkboxSelection={admin}
                disableSelectionOnClick
                autoHeight={true}
                disableColumnMenu
                density="compact"
                components={{
                  Toolbar: GridToolbar,
                  Pagination: CustomPagination,
                }}
                onSelectionModelChange={setSelected}
                selectionModel={selected}
              />
            </CardContent>
          </Card>
          <Modal className="modal-outer" open={idOpenedRow !== null} onClose={closeRow}>
            {idOpenedRow !== null ? (
              <ModalInner
                updateSingle={updateSingle}
                reportsBeingUpdated={reportsBeingUpdated}
                reportBeingUpdated={reportBeingUpdated}
                data={data.find((x) => x.id == idOpenedRow)}
                admin={admin}
              />
            ) : (
              <div />
            )}
          </Modal>
        </Fragment>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
