import ENV from "../ENV";

export async function requestUnrestricted(url, options) {
  let response = await fetch(`${ENV.apiURL}/unrestricted${url}`, options);
  return response.json();
}

export async function requestRestricted(url, options) {
  let _options;
  if (options) {
    _options = {
      ...options,
      credentials: "include",
    };
  } else {
    _options = { credentials: "include" };
  }
  let response = await fetch(`${ENV.apiURL}/restricted${url}`, _options);
  if (response.status != 200) {
    console.log(`error: ${response.status}`);
    return { error: response.status };
  }
  return response.json();
}
