import React, { Fragment, useReducer, useEffect, useMemo } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import papa from "papaparse";
import { DataGrid } from "@mui/x-data-grid";

function getTableData(metric, data) {
  if (!data) return null;
  return data.reduce((acc, { country, institution, publisher, months }) => {
    months.forEach(({ month, data }) => {
      if (typeof data == "object") {
        data.forEach(({ title, platform, publisherTR, DOI, ISBN, PISSN, OISSN, metrics }) => {
          let objTitle = acc.find(
            (x) =>
              x.title == title &&
              x.country == country &&
              x.institution == institution &&
              x.publisher == publisher &&
              x.platform == platform
          );

          if (!objTitle) {
            if (acc.length == 200) return;
            acc.push({
              country,
              institution,
              publisher,
              title,
              platform,
              publisherTR,
              DOI,
              ISBN,
              PISSN,
              OISSN,
              months: months.map((x) => ({
                month: x.month,
                nr: x.data == "unknown" ? -1 : 0,
              })),
            });

            objTitle = acc[acc.length - 1];
          }

          const objMonth = objTitle.months.find((x) => x.month == month);
          objMonth.nr = metrics[metric] ? metrics[metric] : 0;
        });
      }
    });
    return acc;
  }, []);
}

function TableFullDataGrid({ metric, tableData }) {
  const header = [
    "id",
    "country",
    "institution",
    "publisher",
    "title",
    "platform",
    "publisherTR",
    "DOI",
    "ISBN",
    "OISSN",
    "PISSN",
    ...tableData[0].months.map(({ month }) => month),
  ];

  const dataForDataGrid = tableData.map((x, i) => {
    x.id = i;
    x.months.forEach(({ month, nr }) => (x[month] = nr));
    return x;
  });

  const columns = header.map((x) => {
    const column = {
      field: x,
      sortComparator: (v1, v2, param1, param2) => {
        if (typeof param1.value == "object") {
          return param1.value.nr - param2.value.nr;
        } else {
          if (param1.value > param2.value) return 1;
          if (param1.value < param2.value) return -1;
          return 0;
        }
      },
      valueFormatter: (params) => (typeof params.value == "object" ? params.value.value : params.value),
    };
    if (x.includes("20")) {
      column.headerName = <div className="top-row-cell">{x}</div>;
      column.headerClassName = "headerDashboardPR";
      column.cellClassName = "cellDashboardPR";
    } else {
      column.headerName = x;
      column.minWidth = 250;
    }
    return column;
  });

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DataGrid
        rows={dataForDataGrid}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        headerHeight={70}
        autoHeight={true}
        pageSize={20}
        pagination
        density="compact"
      />
    </div>
  );
}

function reducer(state, action) {
  switch (action.type) {
    case "initialize":
      return initialize(action.payload);
    case "switch metric":
      return switchMetric(action.payload);
  }

  function switchMetric(payload) {
    let { metric } = payload;

    return {
      ...state,
      metric,
    };
  }

  function initialize(payload) {
    let { data } = payload;

    if (!data.error) {
      var allMetricTypes = data.allMetricTypes;
      var metric =
        allMetricTypes.indexOf("Total_Item_Investigations") != -1 ? "Total_Item_Investigations" : allMetricTypes[0];
      var _data = data.data;
    } else {
      var allMetricTypes = [];
      var metric = null;
    }

    return {
      ...state,
      data: _data,
      error: data.error !== undefined,
      allMetricTypes,
      metric,
    };
  }
}

export default function DashboardTR(props) {
  let [state, dispatch] = useReducer(reducer, {
    data: null,
    error: props.error !== undefined,
    allMetricTypes: [],
    metric: null,
  });
  let { data, allMetricTypes, error, metric } = state;
  const tableData = useMemo(() => getTableData(metric, data), [data, metric]);

  useEffect(() => {
    dispatch({ type: "initialize", payload: { data: props.data } });
  }, [props]);

  const changeMetric = (event) => {
    dispatch({
      type: "switch metric",
      payload: { metric: event.target.value },
    });
  };

  return (
    <Card className="marginTop">
      <CardContent>
        {(() => {
          if (error) {
            return (
              <Alert variant="outlined" severity="error">
                Server error: {error}
              </Alert>
            );
          }

          if (metric) {
            const csv = papa.unparse([
              [
                "country",
                "institution",
                "publisher",
                "title",
                "platform",
                "publisherTR",
                "DOI",
                "ISBN",
                "OISSN",
                "PISSN",
                ...tableData[0].months.map(({ month }) => month),
              ],
              ...tableData.map(
                ({
                  country,
                  institution,
                  publisher,
                  title,
                  platform,
                  publisherTR,
                  DOI,
                  ISBN,
                  OISSN,
                  PISSN,
                  months,
                }) => [
                  country,
                  institution,
                  publisher,
                  title,
                  platform,
                  publisherTR,
                  DOI,
                  ISBN,
                  OISSN,
                  PISSN,
                  ...months.map(({ nr }) => nr),
                ]
              ),
            ]);
            const blob = new Blob([csv], {
              type: "text/csv",
            });
            const blobUrl = URL.createObjectURL(blob);

            return (
              <Fragment>
                <div className="formControlTop">
                  <Typography variant="h5" gutterBottom className="reportTitle">
                    Report:
                  </Typography>
                  <div className="formControlText">Metric - </div>
                  <FormControl className="formControl">
                    <Select defaultValue={allMetricTypes[0]} value={metric} onChange={changeMetric} label="Metric">
                      {allMetricTypes.map((x) => (
                        <MenuItem key={x} value={x}>
                          {x.replace(/_/g, " ")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <a href={blobUrl} download={`report_${metric}.csv`}>
                    <Button variant="contained" color="primary">
                      Download CSV
                    </Button>
                  </a>
                </div>
                <TableFullDataGrid metric={metric} tableData={tableData} />
              </Fragment>
            );
          }

          return "";
        })()}
      </CardContent>
    </Card>
  );
}
