import React, { useEffect, useReducer } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";

function reducer(state, action) {
  switch (action.type) {
    case "setInputValue":
      return setInputValue(action.payload);
    case "setOptions":
      return setOptions(action.payload);
  }

  function setInputValue(payload) {
    return {
      ...state,
      inputValue: payload,
    };
  }

  function setOptions(payload) {
    return {
      ...state,
      options: payload,
    };
  }
}

export default function Filter({
  searchTR,
  searchType,
  label,
  setValues,
  values,
}) {
  let [state, dispatch] = useReducer(reducer, {
    inputValue: "",
    options: [],
  });
  let { inputValue, options } = state;

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(values ? values : []);
      return;
    }

    fetch(inputValue);

    return () => {
      active = false;
    };

    async function fetch(inputValue) {
      let results = await searchTR(searchType, inputValue);

      if (active) {
        let newOptions = [...values];

        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    }
  }, [values, inputValue]);

  function setOptions(newOptions) {
    dispatch({ type: "setOptions", payload: newOptions });
  }

  function onChange(e, newValues) {
    dispatch({
      type: "setOptions",
      payload: newValues.length ? [...newValues, ...options] : options,
    });
    setValues(newValues);
  }

  function onInputChange(e, newInputValue) {
    dispatch({ type: "setInputValue", payload: newInputValue });
  }

  return (
    <FormControl>
      <Autocomplete
        multiple
        options={options}
        filterSelectedOptions
        value={values}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        style={{ width: 270, marginRight: 10 }}
        getOptionLabel={({ value }) => value}
        getOptionSelected={(o, x) => o.value == x.value}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
      />
    </FormControl>
  );
}
