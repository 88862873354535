const options = {
  DEVELOPMENT: {
    apiURL: "http://localhost:8080",
    CORSorigin: "http://localhost:1234",
  },
  PRODUCTION: {
    apiURL: "https://statistics-api.eifl.net",
    CORSorigin: "https://statistics.eifl.net",
  },
};

module.exports = options[process.env.NODE_ENV == "production" ? "PRODUCTION" : "DEVELOPMENT"];
