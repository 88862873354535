import React, { useEffect, useReducer, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import throttle from "lodash/throttle";

import { requestRestricted } from "../requests";

import Filter from "./Filter";
import DashboardTR from "./DashboardTR";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getDefaultDateTo() {
  let now = new Date();

  return {
    year: now.getFullYear(),
    month: now.getMonth(),
  };
}

function getYears(yearFrom, yearTo) {
  let years = [];
  for (let year = yearFrom; year <= yearTo; year++) {
    years.push(year);
  }
  return years;
}

function generateMonthArray(start, end) {
  let year = parseInt(start.year);
  let month = parseInt(start.month + 1);

  let yearFinal = parseInt(end.year);
  let monthFinal = parseInt(end.month + 1);

  let dates = [];

  while (!(year == yearFinal && month == monthFinal)) {
    dates.push(formatStr(year, month));
    month++;
    if (month == 13) {
      month = 1;
      year++;
    }
  }

  dates.push(formatStr(yearFinal, monthFinal));

  return dates;

  function formatStr(year, month) {
    return `${year}-${month < 10 ? `0${month}` : month}`;
  }
}

function reducer(state, action) {
  switch (action.type) {
    case "initialize":
      return initialize(action.payload);
    case "setValues":
      return setValues(action.payload);
    case "start downloading dashboard data":
      return startDownloading();
    case "finish downloading dashboard data":
      return finishDownloading(action.payload);
    case "change date":
      return setDate(action.payload);
  }

  function initialize(payload) {
    return {
      ...state,
      initialized: true,
      searchTR: throttle(
        (searchType, inputValue) => payload.searchTR(searchType, inputValue),
        200
      ),
    };
  }

  function setValues({ type, values }) {
    return {
      ...state,
      [type]: values,
    };
  }

  function startDownloading() {
    return {
      ...state,
      dataForDashboardBeingDownloading: true,
    };
  }

  function finishDownloading(payload) {
    let { dataForDashboard } = payload;
    return {
      ...state,
      dataForDashboard,
      dataForDashboardBeingDownloading: false,
    };
  }

  function setDate(payload) {
    let { suffix, name, value } = payload;

    if (
      suffix == "From" &&
      name == "year" &&
      (value > state.dateTo.year ||
        (value == state.dateTo.year &&
          state.dateFrom.month > state.dateTo.month))
    ) {
      return state;
    }

    if (
      suffix == "To" &&
      name == "year" &&
      (value < state.dateFrom.year ||
        (value == state.dateFrom.year &&
          state.dateFrom.month > state.dateTo.month))
    ) {
      return state;
    }

    if (
      suffix == "From" &&
      name == "month" &&
      state.dateFrom.year == state.dateTo.year &&
      value > state.dateTo.month
    ) {
      return state;
    }

    if (
      suffix == "To" &&
      name == "month" &&
      state.dateFrom.year == state.dateTo.year &&
      value < state.dateFrom.month
    ) {
      return state;
    }

    return {
      ...state,
      [`date${suffix}`]: {
        ...state[`date${suffix}`],
        [name]: value,
      },
    };
  }
}

function TableTRstatistics({ dataStore }) {
  let [state, dispatch] = useReducer(reducer, {
    initialized: false,
    dataForDashboard: null,
    dataForDashboardBeingDownloading: false,
    valuesPublisher: [],
    valuesInstitution: [],
    valuesCountry: [],
    valuesTitle: [],
    valuesPlatform: [],
    valuesTRPublisher: [],
    valuesDOI: [],
    valuesISBN: [],
    valuesPISSN: [],
    valuesOISSN: [],
    searchTR: null,
    dateFrom: { year: 2021, month: 0 },
    dateTo: getDefaultDateTo(),
  });
  const {
    initialized,
    dataForDashboard,
    dataForDashboardBeingDownloading,
    valuesPublisher,
    valuesInstitution,
    valuesCountry,
    valuesTitle,
    valuesPlatform,
    valuesTRPublisher,
    valuesDOI,
    valuesISBN,
    valuesPISSN,
    valuesOISSN,
    searchTR,
    dateFrom,
    dateTo,
  } = state;

  const eIDs = getEIDs();

  useEffect(() => {
    initialize();

    async function initialize() {
      await dataStore.initSearchTR();
      dispatch({ type: "initialize", payload: dataStore });
    }
  }, []);

  useEffect(() => {
    if (dataForDashboardBeingDownloading) {
      downloadReportData();
    }

    async function downloadReportData() {
      let dataForDashboard = await requestRestricted("/report-data-tr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entryIDs: eIDs,
          months: generateMonthArray(dateFrom, dateTo),
          titles: getValues(valuesTitle),
          platforms: getValues(valuesPlatform),
          publishersTR: getValues(valuesTRPublisher),
          DOIs: getValues(valuesDOI),
          ISBNs: getValues(valuesISBN),
          PISSNs: getValues(valuesPISSN),
          OISSNs: getValues(valuesOISSN),
        }),
      });

      dispatch({
        type: "finish downloading dashboard data",
        payload: { dataForDashboard },
      });

      function getValues(arr) {
        return arr.map((x) => x.value);
      }
    }
  }, [dataForDashboardBeingDownloading]);

  function setValues(type) {
    return function (values) {
      dispatch({
        type: "setValues",
        payload: { type, values },
      });
    };
  }

  function getDataForDashboard() {
    dispatch({ type: "start downloading dashboard data" });
  }

  function changeFromYear(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "From", name: "year", value: e.target.value },
    });
  }

  function changeFromMonth(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "From", name: "month", value: e.target.value },
    });
  }

  function changeToYear(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "To", name: "year", value: e.target.value },
    });
  }

  function changeToMonth(e) {
    dispatch({
      type: "change date",
      payload: { suffix: "To", name: "month", value: e.target.value },
    });
  }

  function getEIDs() {
    const obj = append();

    obj.append(valuesPublisher);
    obj.append(valuesInstitution);
    obj.append(valuesCountry);
    obj.append(valuesTitle);
    obj.append(valuesPlatform);
    obj.append(valuesTRPublisher);
    obj.append(valuesDOI);
    obj.append(valuesISBN);
    obj.append(valuesPISSN);
    obj.append(valuesOISSN);

    return obj.return();

    function append() {
      let eIDs = [];
      let started = false;
      return {
        append: function (arr) {
          if (!started) {
            if (arr.length > 0) {
              eIDs = mapEIDs(arr);
              started = true;
            }
          } else {
            if (arr.length > 0) {
              const _eIDs = mapEIDs(arr);
              eIDs = eIDs.filter((x) => _eIDs.indexOf(x) != -1);
            }
          }

          function mapEIDs(arr) {
            return arr
              .reduce((acc, cur) => [...acc, ...cur.eIDs], [])
              .filter((x, i, arr) => arr.indexOf(x) == i);
          }
        },
        return: function () {
          return eIDs;
        },
      };
    }
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {initialized ? (
        <Fragment>
          <Card className="topControlRow">
            <CardContent className="formControlTop">
              <Button
                variant="contained"
                color="primary"
                disabled={eIDs.length == 0 || dataForDashboardBeingDownloading}
                onClick={getDataForDashboard}
              >
                Generate Report
              </Button>
              <div className="formControlText">From: </div>
              <FormControl className="formControl">
                <Select value={dateFrom.year} onChange={changeFromYear}>
                  {getYears(2019, dateTo.year).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="formControl">
                <Select value={dateFrom.month} onChange={changeFromMonth}>
                  {months.map((month, i) => (
                    <MenuItem key={month} value={i}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="formControlText">To: </div>
              <FormControl className="formControl">
                <Select value={dateTo.year} onChange={changeToYear}>
                  {getYears(dateFrom.year, getDefaultDateTo().year).map(
                    (year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl className="formControl">
                <Select value={dateTo.month} onChange={changeToMonth}>
                  {months.map((month, i) => (
                    <MenuItem key={month} value={i}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
            <CardContent className="formControlTop">
              <div className="formControlText">Filters: </div>
              <div>
                <div>
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - PUBLISHER"
                    label="Publisher"
                    setValues={setValues("valuesPublisher")}
                    values={valuesPublisher}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - INSTITUTION"
                    label="Institution"
                    setValues={setValues("valuesInstitution")}
                    values={valuesInstitution}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - COUNTRY"
                    label="Country"
                    setValues={setValues("valuesCountry")}
                    values={valuesCountry}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - TITLE"
                    label="Title"
                    setValues={setValues("valuesTitle")}
                    values={valuesTitle}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - PLATFORM"
                    label="Platform"
                    setValues={setValues("valuesPlatform")}
                    values={valuesPlatform}
                  />
                </div>
                <div>
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - TR PUBLISHER"
                    label="TR Publisher"
                    setValues={setValues("valuesTRPublisher")}
                    values={valuesTRPublisher}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - DOI"
                    label="DOI"
                    setValues={setValues("valuesDOI")}
                    values={valuesDOI}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - ISBN"
                    label="ISBN"
                    setValues={setValues("valuesISBN")}
                    values={valuesISBN}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - PRINT ISSN"
                    label="Print ISSN"
                    setValues={setValues("valuesPISSN")}
                    values={valuesPISSN}
                  />
                  <Filter
                    searchTR={searchTR}
                    searchType="SEARCH - ONLINE ISSN"
                    label="Online ISSN"
                    setValues={setValues("valuesOISSN")}
                    values={valuesOISSN}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          {dataForDashboard ? <DashboardTR data={dataForDashboard} /> : ""}
        </Fragment>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default TableTRstatistics;
