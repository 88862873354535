import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    "& label.Mui-focused": {
      color: "red !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red !important",
      },
      "&:hover fieldset": {
        borderColor: "red !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "red !important",
      },
    },
  },
  logo: {
    marginBottom: "10px",
  },
}));

function LoginPage(props) {
  let [password, setPassword] = useState("");
  let [username, setUsername] = useState("");
  const classes = useStyles();

  function handlePassword(event) {
    setPassword(event.target.value);
  }

  function handleUsername(event) {
    setUsername(event.target.value);
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          props.logIn({ username, password });
        }
      }}
    >
      <div className={classes.paper}>
        <img src="https://statistics.eifl.net/static/logo.png" className={classes.logo} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div className={classes.form}>
          <FormControl className={props.loginError == "no user found" ? classes.error : ""} margin="normal" fullWidth>
            <InputLabel variant="outlined">Username</InputLabel>
            <OutlinedInput label="Password" value={username} onChange={handleUsername} />
          </FormControl>
          <FormControl className={props.loginError == "wrong password" ? classes.error : ""} margin="normal" fullWidth>
            <InputLabel variant="outlined">Password</InputLabel>
            <OutlinedInput label="Username" value={password} type="password" onChange={handlePassword} />
          </FormControl>
          <Button
            onClick={() => props.logIn({ username, password })}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default LoginPage;
