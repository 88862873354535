import React, { useReducer, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import LoginPage from "./LoginPage";
import LoggedIn from "./LoggedIn";

import { requestUnrestricted, requestRestricted } from "./requests";
import dataStore from "./dataStore";

function reducer(state, action) {
  switch (action.type) {
    case "log in":
      return {
        ...state,
        loggedIn: true,
        loginError: "none",
        userAdmin: action.userAdmin,
        initialized: true,
      };
    case "log out":
      return {
        ...state,
        userAdmin: false,
        loggedIn: false,
        loginError: "none",
      };
    case "no user found":
      return { ...state, loginError: "no user found" };
    case "wrong password":
      return { ...state, loginError: "wrong password" };
    case "initialize":
      return { ...state, initialized: true };
    default:
      throw new Error("wrong action type");
  }
}

function App() {
  let [state, dispatch] = useReducer(
    reducer,
    {
      userAdmin: false,
      loggedIn: false,
      loginError: "none",
      initialized: false,
    },
    init
  );

  useEffect(() => {
    logInWithCookie();
  }, []);

  function init(state) {
    return { ...state, dataStore: dataStore() };
  }

  function logOut() {
    requestUnrestricted("/logout", {
      method: "POST",
      credentials: "include",
    });
    dispatch({ type: "log out" });
  }

  async function logInWithCookie() {
    let status = await requestUnrestricted("/check-cookie", {
      method: "POST",
      credentials: "include",
    });
    if (status.success) {
      dispatch({ type: "log in", userAdmin: status.type == "admin" });
    } else {
      dispatch({ type: "initialize" });
    }
  }

  async function sendCredentials(credentials) {
    let status = await requestUnrestricted("/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(credentials),
    });
    if (!status.success) {
      if (status.body == "No user found") {
        dispatch({ type: "no user found" });
        return;
      }
      if (status.body == "Wrong password") {
        dispatch({ type: "wrong password" });
        return;
      }
    } else {
      dispatch({ type: "log in", userAdmin: status.type == "admin" });
    }
  }

  if (!state.initialized) return "";

  return (
    <Router>
      <Switch>
        <Route path="/login">
          {state.loggedIn ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            <LoginPage logIn={sendCredentials} loginError={state.loginError} />
          )}
        </Route>
        <Route path="/">
          {state.loggedIn ? (
            <LoggedIn admin={state.userAdmin} dataStore={state.dataStore} logOut={logOut} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
